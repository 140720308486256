<template>
    <v-container class="actor">
        <v-img
            :src="require(`@/assets/images/emotions/${vote.iconFile}`)"
            class="icon-emotion"
            width="90" />

        <div class="question">{{ questionComputed }}</div>

        <div class="list-actors">
            <div
                v-for="(actor, index) in actors"
                :key="actor.id"
                class="item-actor"
                :class="itemComputed(index)"
                @click="submitForm(actor)">
                {{ actor.name }}
            </div>
        </div>
        <div class="other-actor" @click="goToComponent('actorMessage')">Outra opção</div>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { isAmbientGeralTests } from '@/configs/global.config';
    import actorMock from '../../mocks/campaign/actor.mock';

    export default {
        name: 'Actor',
        data() {
            return {
                isAmbientLocalTests: false,
                loadingActivated: false,
                nextComponent: 'reason',
                actors: []
            };
        },
        mounted() {
            this.populateGeral();
        },
        computed: {
            ...mapGetters({
                vote: 'getDataVote'
            }),
            questionComputed() {
                return `${this.vote.emotionName.toLowerCase()}? 
                ${this.vote.emotionPreposition} quem?`;
            },
            itemComputed() {
                return (index) => {
                    return {
                        'item-first-and-second': index <= 1 ? true : false,
                        'item-actor-left': index % 2 === 0 ? true : false
                    };
                };
            }
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            populateGeral() {
                if (this.isAmbientTests()) {
                    this.actors = actorMock.actors;
                } else {
                    this.serviceLoadingActors();
                }
            },
            setDataInStore(actorId, actorSlug) {
                this.$store.dispatch('setActorId', actorId);

                this.$store.dispatch('setActorSlug', actorSlug);

                this.goToComponent(this.nextComponent);
            },
            goToComponent(component) {
                this.$emit('activeComponent', component);
            },
            filterActorsToLoading(actors) {
                return actors.slice(0, 6);
            },
            serviceLoadingActors() {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['user']
                    .getListActors()
                    .then((result) => {
                        const records = result.data.docs;

                        const dataFiltered = this.filterActorsToLoading(records);

                        this.actors = dataFiltered;
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            async createVoteActorMessage(actorName) {
                const data = {
                    id: this.vote.id,
                    actorName: actorName
                };

                await this.$service.http['user']
                    .createVoteActorMessage(data)
                    .then((_) => null)
                    .catch((e) => {
                        console.error(e);
                    });
            },
            submitForm(actor) {
                this.$store.commit('setGlobalLoading', true);

                this.$store.dispatch('setActorId', actor.id);

                this.$service.http['user']
                    .createVoteActor(this.vote)
                    .then(async (_) => {
                        await this.createVoteActorMessage(actor.name);

                        this.goToComponent(this.nextComponent);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style>
    .actor {
        margin-top: 10px;
    }

    .actor .icon-emotion {
        cursor: pointer;
        margin: auto;
    }

    .actor .question {
        margin-top: 5px;
        text-align: center;
        font-size: 23px;
        font-weight: bold;
    }

    .actor .list-actors {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
        padding: 25px;
    }

    .actor .list-actors .item-actor {
        width: 47%;
        margin-top: 15px;
        padding: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #ece8f6;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        color: #7b009c;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        cursor: pointer;
    }

    .other-actor {
        width: 200px;
        margin: auto;
        padding: 15px;
        background-color: #ffffff;
        border: 3px solid #7b009c;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #7b009c;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .actor .list-actors .item-actor-left {
        margin-right: 15px;
    }

    .actor .list-actors .item-first-and-second {
        margin-top: 0px;
    }

    .do-not-inform {
        width: 300px;
        margin: auto;
        padding: 7px;
        background-color: #ffffff;
        border: 3px solid #ddd;
        text-align: center;
        font-size: 18px;
        color: #888;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        cursor: pointer;
        margin-bottom: 30px;
    }
</style>
