export default {
    reasons: [
        { id: 1, emotionSlug: 'sad', description: 'Me apelidou' },
        { id: 2, emotionSlug: 'sad', description: 'Me bateu' },
        { id: 3, emotionSlug: 'sad', description: 'Me xingou' },
        { id: 4, emotionSlug: 'sad', description: 'Me assediou' },
        { id: 5, emotionSlug: 'sad', description: 'Me humilhou' },
        { id: 6, emotionSlug: 'sad', description: 'Me ameaçou' },
        { id: 7, emotionSlug: 'angry', description: 'Me apelidou' },
        { id: 8, emotionSlug: 'angry', description: 'Me bateu' },
        { id: 9, emotionSlug: 'angry', description: 'Me xingou' },
        { id: 10, emotionSlug: 'angry', description: 'Me assediou' },
        { id: 11, emotionSlug: 'angry', description: 'Me humilhou' },
        { id: 12, emotionSlug: 'angry', description: 'Me ameaçou' },
        { id: 13, emotionSlug: 'fear', description: 'Me apelidou' },
        { id: 14, emotionSlug: 'fear', description: 'Me bateu' },
        { id: 15, emotionSlug: 'fear', description: 'Me xingou' },
        { id: 16, emotionSlug: 'fear', description: 'Me assediou' },
        { id: 17, emotionSlug: 'fear', description: 'Me humilhou' },
        { id: 18, emotionSlug: 'fear', description: 'Me ameaçou' },
        { id: 19, emotionSlug: 'happy', description: 'Me presenteou' },
        { id: 20, emotionSlug: 'happy', description: 'Me abraçou' },
        { id: 21, emotionSlug: 'happy', description: 'Me fez rir' },
        { id: 22, emotionSlug: 'happy', description: 'Me beijou' },
        { id: 23, emotionSlug: 'happy', description: 'Me fofou' },
        { id: 24, emotionSlug: 'happy', description: 'Me engraçou' }
    ]
};
