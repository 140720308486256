<template>
    <v-container class="reason">
        <v-img
            :src="require(`@/assets/images/emotions/${vote.iconFile}`)"
            class="icon-emotion"
            width="90" />

        <div class="question">{{ questionComputed }}</div>

        <div class="list-reasons">
            <div
                v-for="(reason, index) in reasons"
                :key="reason.id"
                class="item-reason"
                :class="computedItemComputed(index)"
                @click="submitForm(reason)">
                {{ reason.description }}
            </div>
        </div>

        <div class="other-reason" @click="goToComponent('reasonMessage')">
            Outra opção
        </div>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { isAmbientGeralTests } from '@/configs/global.config';
    import reasonMock from '../../mocks/campaign/reason.mock';

    export default {
        name: 'Reason',
        data() {
            return {
                isAmbientLocalTests: false,
                loadingActivated: false,
                nextComponent: 'successMessage',
                reasons: null
            };
        },
        mounted() {
            this.geralLoading();
        },
        computed: {
            ...mapGetters({
                vote: 'getDataVote'
            }),
            questionComputed() {
                return `${this.vote.emotionName}? qual o motivo?`;
            },
            computedItemComputed() {
                return (index) => {
                    return {
                        'item-first-and-second': index <= 1 ? true : false,
                        'item-reason-left': index % 2 === 0 ? true : false
                    };
                };
            }
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            geralLoading() {
                if (this.isAmbientTests()) {
                    const emotionSlug = this.vote.emotionSlug;

                    this.reasons = reasonMock.reasons.filter(
                        (e) => e.emotionSlug === emotionSlug
                    );
                } else {
                    this.serviceLoadingReasons();
                }
            },
            filterReasonsToLoading(reasons) {
                const emotionId = this.vote.emotionId;

                return reasons.filter((e) => e.emotionId === emotionId).slice(0, 6);
            },
            serviceLoadingReasons() {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['user']
                    .getListReasons()
                    .then((result) => {
                        const dataFiltered = this.filterReasonsToLoading(
                            result.data.docs
                        );

                        this.reasons = dataFiltered;
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            goToComponent(component) {
                this.$emit('activeComponent', component);
            },
            async createVoteReasonMessage(data) {
                await this.$service.http['user']
                    .createVoteReasonMessage(data)
                    .then((_) => null)
                    .catch((e) => {
                        console.error(e);
                    });
            },
            submitForm(reason) {
                this.$store.commit('setGlobalLoading', true);

                this.$store.dispatch('setReasonId', reason.id);

                this.$service.http['user']
                    .createVoteReason(this.vote)
                    .then(async (_) => {
                        const voteId = this.vote.id;
                        const reasonMessage = reason.description;

                        const data = {
                            id: voteId,
                            reasonMessage
                        };

                        await this.createVoteReasonMessage(data);

                        this.goToComponent(this.nextComponent);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style>
    .reason {
        margin-top: 10px;
    }

    .reason .icon-emotion {
        margin: auto;
    }

    .reason .question {
        margin-top: 5px;
        text-align: center;
        font-size: 23px;
        font-weight: bold;
    }

    .reason .list-reasons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
        padding: 25px;
    }

    .reason .list-reasons .item-reason {
        width: 47%;
        margin-top: 15px;
        padding: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #ece8f6;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        color: #7b009c;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        cursor: pointer;
    }

    .other-reason {
        width: 200px;
        margin: auto;
        padding: 15px;
        background-color: #ffffff;
        border: 3px solid #7b009c;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #7b009c;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .reason .list-reasons .item-reason-left {
        margin-right: 15px;
    }

    .reason .list-reasons .item-first-and-second {
        margin-top: 0px;
    }
</style>
