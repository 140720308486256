<template>
    <v-container class="reason">
        <v-img
            :src="require(`@/assets/images/emotions/${vote.iconFile}`)"
            class="icon-emotion"
            width="90" />

        <div class="question">{{ questionComputed }}</div>

        <div class="box">
            <div class="box-area">
                <v-textarea
                    v-model="reasonMessage"
                    class="input-area"
                    name="description"
                    placeholder="Digite aqui..."
                    rounded
                    solid
                    value="Mensagem...."></v-textarea>
            </div>

            <v-btn
                color="secondary"
                rounded
                x-large
                elevation="0"
                @click="submitForm()"
                class="submit-form">
                Enviar
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'ReasonMessage',
        data() {
            return {
                nextComponent: 'successMessage',
                reasonMessage: null
            };
        },
        computed: {
            ...mapGetters({
                vote: 'getDataVote'
            }),
            questionComputed() {
                return `${this.vote.emotionName}? qual o motivo?`;
            }
        },
        methods: {
            validateForm() {
                const reasonMessage = this.reasonMessage;

                if (!reasonMessage) {
                    return {
                        valid: false,
                        message: 'Insira uma mensagem!'
                    };
                }

                if (reasonMessage.length <= 2) {
                    return {
                        valid: false,
                        message: 'Sua mensagem está muito curta!'
                    };
                }

                if (reasonMessage.length > 255) {
                    return {
                        valid: false,
                        message: 'Sua mensagem está muito longa!'
                    };
                }

                return {
                    valid: true,
                    message: null
                };
            },
            goToComponent(component) {
                this.$emit('activeComponent', component);
            },
            submitForm() {
                const { valid } = this.validateForm();

                if (!valid) return;

                const data = {
                    id: this.vote.id,
                    reasonMessage: this.reasonMessage
                };

                this.$service.http['user']
                    .createVoteReasonMessage(data)
                    .then((_) => {
                        this.$store.commit('setGlobalLoading', false);

                        this.goToComponent(this.nextComponent);
                    })
                    .catch((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style>
    .reason .box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .reason .box .box-area {
        margin: 20px;
    }

    .reason .box .box-area .input-area {
        width: 350px;
        background-color: #eee;
    }

    .reason .box .submit-form {
        width: 140px;
        margin-bottom: 20px;
        text-transform: none !important;
        font-size: 18px;
        letter-spacing: 0px !important;
        font-weight: bold;
    }
</style>
