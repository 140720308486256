<template>
    <v-container fill-height>
        <v-layout justify-center align-center>
            <v-flex xs12 sm6 md4 lg3 xl3>
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title>Votar</v-toolbar-title>
                    </v-toolbar>
                    <keep-alive>
                        <component
                            :is="componentSelected"
                            @activeComponent="activeComponent($event)" />
                    </keep-alive>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Emotion from '@/modules/user/components/campaign/Emotion.vue';
    import Actor from '@/modules/user/components/campaign/Actor.vue';
    import Reason from '@/modules/user/components/campaign/Reason.vue';
    import ActorMessage from '@/modules/user/components/campaign/ActorMessage.vue';
    import ReasonMessage from '@/modules/user/components/campaign/ReasonMessage.vue';
    import SuccessMessage from '@/modules/user/components/campaign/SuccessMessage.vue';

    export default {
        components: {
            Emotion,
            Actor,
            Reason,
            ActorMessage,
            ReasonMessage,
            SuccessMessage
        },
        name: 'Campaign',
        data() {
            return {
                componentSelected: null,
                nextComponent: 'emotion'
            };
        },
        mounted() {
            this.saveVoteDataInStore();

            this.goToComponent(this.nextComponent);
        },
        computed: {
            ...mapGetters({
                user: 'getDataUser'
            })
        },
        methods: {
            activeComponent(component) {
                this.goToComponent(component);
            },
            saveVoteDataInStore() {
                const userId = this.user.id;

                this.$store.dispatch('setUserId', userId);
            },
            goToComponent(component) {
                const map = {
                    emotion: 'Emotion',
                    actor: 'Actor',
                    reason: 'Reason',
                    actorMessage: 'ActorMessage',
                    reasonMessage: 'ReasonMessage',
                    successMessage: 'SuccessMessage'
                };

                const exists = map.hasOwnProperty(component);

                this.componentSelected = exists ? map[component] : map['emotion'];
            }
        }
    };
</script>

<style scoped></style>
