<template>
    <v-container class="vote">
        <h1 class="hello">{{ helloComputed }}</h1>

        <div class="question">{{ titles.question }}</div>

        <v-alert
            class="vote-alert"
            outlined
            type="warning"
            prominent
            border="left"
            v-show="alertActivated">
            <strong>Para votar</strong>, basta clicar em um emotion!
        </v-alert>

        <div class="emotions">
            <div
                v-for="(emotion, index) in emotions"
                :key="emotion.id"
                class="box-emotion"
                :class="itemComputed(index)">
                <v-img
                    :src="require(`@/assets/images/emotions/${emotion.iconFile}`)"
                    class="icon-emotion"
                    :title="emotion.name"
                    :alt="emotion.name"
                    width="70"
                    @click="
                        submitForm(
                            emotion.id,
                            emotion.name,
                            emotion.preposition,
                            emotion.iconFile
                        )
                    " />

                <div class="name">{{ emotion.name }}</div>
            </div>
        </div>

        <div class="campaign">
            <div class="division"></div>

            <div class="company">
                <div class="name">{{ vote.company.name }}</div>

                <div class="group">{{ vote.group.name }}</div>
            </div>
        </div>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { isAmbientGeralTests } from '@/configs/global.config';
    import emotionMock from '../../mocks/campaign/emotion.mock';

    export default {
        name: 'Emotion',
        data() {
            return {
                isAmbientLocalTests: true,
                loadingActivated: false,
                alertActivated: true,
                nextComponent: 'actor',
                titles: {
                    question: 'Como está se sentindo?'
                },
                emotions: null
            };
        },
        computed: {
            ...mapGetters({
                user: 'getDataUser',
                vote: 'getDataVote'
            }),
            helloComputed() {
                let userName = this.user.name;

                userName = userName.split(' ')[0];

                return `Olá ${userName}!`;
            },
            itemComputed() {
                return (index) => {
                    return {
                        'item-first': !index ? true : false
                    };
                };
            }
        },
        mounted() {
            this.geralLoading();
            this.serviceLoadingEmotions();
        },
        methods: {
            isAmbientTests() {
                return isAmbientGeralTests && this.isAmbientLocalTests;
            },
            geralLoading() {
                if (this.isAmbientTests()) {
                    this.emotions = emotionMock.emotions;
                } else {
                    this.serviceLoadingEmotions();
                }
            },
            setEmotionDataInStore(emotionId, emotionName, emotionPreposition, iconFile) {
                this.$store.dispatch('setEmotionId', emotionId);
                this.$store.dispatch('setEmotionName', emotionName);
                this.$store.dispatch('setEmotionPreposition', emotionPreposition);
                this.$store.dispatch('setIconFile', iconFile);
            },
            goToComponent(component) {
                this.$emit('activeComponent', component);
            },
            serviceLoadingEmotions() {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['user']
                    .getListEmotions()
                    .then((result) => {
                        this.emotions = result.data.docs;
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            submitForm(emotionId, emotionName, emotionPreposition, iconFile) {
                this.$store.commit('setGlobalLoading', true);

                this.setEmotionDataInStore(
                    emotionId,
                    emotionName,
                    emotionPreposition,
                    iconFile
                );

                this.$service.http['user']
                    .createVote(this.vote)
                    .then((result) => {
                        const voteId = result.data.doc.id;

                        this.$store.dispatch('setId', voteId);

                        this.$store.commit('setGlobalLoading', false);

                        this.goToComponent(this.nextComponent);
                    })
                    .catch((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style>
    .vote {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
    }

    .vote-alert {
        margin: 20px;
    }

    .vote .hello {
        margin-top: 30px;
        color: #ddd;
        text-align: center;
        font-size: 28px;
        color: #7b009c;
    }

    .vote .question {
        margin-top: 30px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }

    .emotions {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .emotions .box-emotion {
        margin-left: 25px;
    }

    .emotions .item-first {
        margin-left: 0px;
    }

    .box-emotion .emotion {
        height: 70px;
        width: 70px;
        cursor: pointer;
        background-size: cover;
    }

    .icon-emotion {
        cursor: pointer;
    }

    .emotions .box-emotion .name {
        margin-top: 10px;
        text-align: center;
    }

    .campaign {
        margin-top: 30px;
        padding: 30px;
    }

    .campaign .division {
        width: 150px;
        height: 1px;
        background-color: #ccc;
    }

    .campaign .company {
        margin-top: 20px;
    }

    .company .name {
        color: #777;
    }

    .company .group {
        font-weight: bold;
        color: #7b009c;
    }
</style>
