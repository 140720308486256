export default {
    actors: [
        { id: 1, slug: 'pais', name: 'Pais' },
        { id: 2, slug: 'amigos', name: 'Amigos' },
        { id: 3, slug: 'professor', name: 'Professor(a)' },
        { id: 4, slug: 'tio', name: 'Tio' },
        { id: 5, slug: 'familiar', name: 'Familiar' },
        { id: 6, slug: 'uma pessoa', name: 'Uma Pessoa' }
    ]
};
