<template>
    <v-container class="container">
        <v-img
            :src="require(`@/assets/images/emotions/success.svg`)"
            class="icon-emotion"
            width="90" />

        <div class="text-success">Voto concluído!</div>

        <div class="redirect">Redirecionando em {{ timeToRedirect }} segundos...</div>
    </v-container>
</template>

<script>
    export default {
        name: 'SuccessMessage',
        data() {
            return {
                iconSuccess: 'mdi-success',
                timeToRedirect: 5
            };
        },
        mounted() {
            this.redirect();
        },
        methods: {
            goTo() {
                return this.$router.push({ name: 'queue' });
            },
            redirect() {
                setInterval(() => this.timeToRedirect--, 1000);

                setTimeout(() => this.goTo(), this.timeToRedirect * 1000);
            }
        }
    };
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .icon-success {
        margin-top: 30px;
        width: 85px;
        height: 85px;
        background-size: cover;
    }

    .text-success {
        margin-top: 10px;
        font-weight: bold;
        font-size: 24px;
    }

    .redirect {
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>
